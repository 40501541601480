import React, { memo, useCallback, useState, useRef, useEffect } from "react";
import moment from "moment";
import media from "styled-media-query";
import styled from "styled-components";
import transition from "styled-transition-group";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import useGetProgramsV2 from "hooks/ProgramsV2/useGetProgramsV2";
import { H3, P1 } from "app/components/Typography";
import { ScreenGtLg, ScreenLtMd } from "app/components/MediaQueries";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import Flex from "app/components/Flex";
import ResponsiveVideoPlayer from "app/components/ResponsiveVideoPlayer";
import { useDispatch, useSelector } from "react-redux";
import { throttle } from "lodash";
import { setTopmostBlockId } from "modules/program-view";
import PropTypes from "prop-types";
import PreviewOverlay from "./ProgramOverviewComponents/PreviewOverlay";
import ProgramInfo from "./ProgramOverviewComponents/ProgramInfo";
import ProgramClasses from "./ProgramClasses";
import RelatedClasses from "./RelatedClasses";
import InstructorCard from "./ProgramOverviewComponents/InstructorCard";
import ViewMoreContainer from "./ProgramOverviewComponents/ViewMoreContainer";
import {
  InstructorName,
  InstructorCreditsAndBio,
  ViewLessCTA,
} from "./ProgramOverviewComponents/InstructorCard/InstructorInfo";
import TrailerModal from "./TrailerModal";
import SideNav from "./SideNav";

const ResponsiveContainer = styled(Flex).attrs({
  maxWidth: { _: "768px", programsLg: "1300px" },
  mx: { programsLg: "150px", _: 3 },
})``;

const PlayerWrapper = styled(Flex)`
  width: 100vw;
  height: 60vh;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  ${media.greaterThan("large")`
    height: 80vh;
  `}
`;

const OverviewContainer = styled(Flex)`
  position: relative;
  width: 100vw;
  height: auto;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.monochrome[8]};
  z-index: 2;
`;

const Gradient = styled(Flex)`
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    transparent 30%,
    ${({ theme }) => theme.colors.monochrome[8]} 100%
  );
`;

const FadeOutText = transition.div`
  width: 100%;
  max-height: ${({ isOverviewExpanded }) =>
    isOverviewExpanded ? "100%" : "inherit"}
  
  &:exit {
    opacity: 0;
  }

  &:exit-active {
    opacity: 0;
  }

  &:enter {
    opacity: 1;
  }

  &:enter-active {
    opacity: 0;
  }
`;

const AllClasses = memo(({ isOverviewExpanded }) => {
  return (
    <Flex width="100%" justifyContent="center">
      <ResponsiveContainer
        flexDirection="column"
        mb="48px"
        mt={!isOverviewExpanded && "48px"}
        width="100%"
        height="100%"
        justifyContent="center"
      >
        <ProgramClasses isOverviewExpanded={isOverviewExpanded} />
        <RelatedClasses />
      </ResponsiveContainer>
    </Flex>
  );
});

AllClasses.propTypes = {
  isOverviewExpanded: PropTypes.bool.isRequired,
};

const ProgramsV2 = () => {
  // Using this ref to set margins on the instructor thumbnail
  const overviewContainerRef = useRef();
  const dispatch = useDispatch();

  const { isTrailerOpen } = useSelector(state => state.programView);
  const [isOverviewExpanded, setIsOverviewExpanded] = useState(false);

  const { slug } = useParams();
  const [programsV2Data, loading, error] = useGetProgramsV2({
    variables: { slug },
    options: {
      nextFetchPolicy: "cache-first",
      onCompleted: ({ getProgramV2: programData }) => {
        if (!programData?.progress?.startedDate) {
          setIsOverviewExpanded(true);
        }
      },
    },
  });

  const scrollIntersectCallback = useCallback(
    throttle(entries => {
      let min = Number.MAX_VALUE;
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        }
        if (entry.boundingClientRect.top < min) {
          dispatch(setTopmostBlockId(entry.target.id));
          min = entry.boundingClientRect.top;
        }
      });
    }, 200),
    [dispatch]
  );

  useEffect(() => {
    if (loading) {
      return undefined;
    }
    const options = {
      rootMargin: "60px",
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(scrollIntersectCallback, options);
    const targets = Array.from(document.getElementsByClassName("watch-scroll"));

    targets.forEach(target => {
      observer.observe(target);
    });
    return () => {
      targets.forEach(target => {
        observer.unobserve(target);
      });
    };
  }, [dispatch, loading, scrollIntersectCallback]);

  if (loading) {
    return <LoaderCentered />;
  }

  if (error) {
    return <Redirect to="/not-found" />;
  }

  if (
    programsV2Data.publishDate &&
    moment().isBefore(programsV2Data.publishDate)
  ) {
    return <Redirect to="/programs" />;
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      height="100%"
      overflowY="auto"
      overflowX="hidden"
    >
      <TrailerModal />
      <ScreenGtLg>
        <SideNav fixedToViewport />
      </ScreenGtLg>
      <OverviewContainer
        ref={overviewContainerRef}
        mb={
          isOverviewExpanded && {
            _: overviewContainerRef.current?.offsetHeight * 0.05,
            md: overviewContainerRef.current?.offsetHeight * 0.2,
          }
        }
      >
        <ScreenGtLg>
          <SideNav white fixedToViewport={false} />
        </ScreenGtLg>
        <PlayerWrapper id="program-overview-container" className="watch-scroll">
          <ResponsiveVideoPlayer
            objectFit="cover"
            playsinline
            playing={!isTrailerOpen}
            muted
            loop
            showLoader={false}
            url={programsV2Data?.content?.assets?.previewVideoURL}
          />
          <Gradient />
          <PreviewOverlay />
        </PlayerWrapper>
        <ResponsiveContainer
          flexDirection="column"
          justifyContent="start"
          alignItems="center"
          my={!isOverviewExpanded ? 0 : 4}
          // Potential issues with setting this max-height to 2500px; we may have to make this number bigger if the container gets too big!
          maxHeight={!isOverviewExpanded ? "100px" : "2500px"}
          transition="all 600ms ease"
          overflow={!isOverviewExpanded && "hidden"}
        >
          <FadeOutText
            position="relative"
            timeout={{ enter: 200, exit: 300 }}
            in={isOverviewExpanded}
            transition="opacity 250ms ease"
            isOverviewExpanded={isOverviewExpanded}
          >
            <Flex
              flexDirection="column"
              overflow={!isOverviewExpanded && "hidden"}
              height={!isOverviewExpanded && "60px"}
              pb={!isOverviewExpanded ? 0 : 4}
              mb={!isOverviewExpanded ? 0 : 4}
              isOverviewExpanded={isOverviewExpanded}
              borderBottom={isOverviewExpanded && "solid 2px"}
              borderColor={isOverviewExpanded && "monochrome.7"}
            >
              <H3 color={!isOverviewExpanded ? "monochrome.3" : "white"} mb={3}>
                {programsV2Data?.content?.introduction?.title}
              </H3>
              <P1 color="monochrome.3">
                {programsV2Data?.content?.introduction?.description}
              </P1>
            </Flex>
            <ViewMoreContainer
              position="absolute"
              isOverviewExpanded={isOverviewExpanded}
              setIsOverviewExpanded={setIsOverviewExpanded}
            >
              <ProgramInfo mt={!isOverviewExpanded && 4} />
              <ScreenLtMd>
                <InstructorName
                  name={programsV2Data?.instructor?.name}
                  color="white"
                  alignSelf="flex-start"
                  mb={4}
                />
              </ScreenLtMd>
              <InstructorCard
                containerHeight={overviewContainerRef.current?.offsetHeight}
                isOverviewExpanded={isOverviewExpanded}
                setIsOverviewExpanded={setIsOverviewExpanded}
              />
            </ViewMoreContainer>
          </FadeOutText>
        </ResponsiveContainer>
      </OverviewContainer>
      {isOverviewExpanded && (
        <ScreenLtMd>
          <Flex flexDirection="column" my={4} mx={3}>
            <InstructorCreditsAndBio
              bio={programsV2Data?.instructor?.bio}
              credits={programsV2Data?.instructor?.credits}
              flexDirection="column"
              descriptionColor="monochrome.7"
            />
            <ViewLessCTA
              textAlign="center"
              mt={3}
              setIsOverviewExpanded={setIsOverviewExpanded}
            />
          </Flex>
        </ScreenLtMd>
      )}
      <AllClasses isOverviewExpanded={isOverviewExpanded} />
    </Flex>
  );
};

export default ProgramsV2;
